window.analytics = function(action, label, value = 0) {
  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      'event_category': 'OneAcreFund',
      'event_label': label,
      'value': value
    });
  }
};

$(document).ready(function(){
    $('#cta-mobile').click(function() {
        $(".oaf-cta-mobile-content").addClass('hide-mobile-cta');
        $(".oaf-cta-desktop-content").addClass("show-all-cta");
    });

    /* on facebook button click */
    $('.btn-facebook-js').on('click', function () {
        var here = window.location.href.split('?')[0];
        var utm_source = $(this).data('utm-source');
        var utm_medium = $(this).data('utm-medium');
        var utm_campaign = $(this).data('utm-campaign');
        var utm_content = $(this).data('utm-content');
        here = here + '?utm_source=' + utm_source + '&utm_medium=' + utm_medium + '&utm_campaign=' + utm_campaign + '&utm_content=' + utm_content;
        here = here.replace('/thank-you', '');
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(here), '_blank', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250,top=300,left=300');
        analytics('share', 'facebook');
    });
    
    /* on whatsapp button click */
    $('.btn-whatsapp-js').on('click', function () {
        var here = window.location.href.split('?')[0];
        var utm_source = $(this).data('utm-source');
        var utm_medium = $(this).data('utm-medium');
        var utm_campaign = $(this).data('utm-campaign');
        var utm_content = $(this).data('utm-content');
        here = here + '?utm_source=' + utm_source + '&utm_medium=' + utm_medium + '&utm_campaign=' + utm_campaign + '&utm_content=' + utm_content;
        here = here.replace('/thank-you', '');
        var shareText = $(this).data('text') + ' ' + here;
        window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(shareText), '_blank');
        analytics('share', 'whatsapp');
    });
});